/* global APP_INFO, CONFIG */
/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import logger from "debug";
import compareVersions from "compare-versions";
import PageVisibility from "react-page-visibility";
import fetchWaitingToSyncCount from "~/App/sync";

const isAppUpdateAvailable = ({ version, buildNumber }) => {
  switch (compareVersions(version, APP_INFO.version)) {
    case 0:
      return buildNumber > APP_INFO.buildNumber;
    case 1:
      return true;
    default:
      return false;
  }
};

const App = ({
  children,
  setLastSync,
  showAppMessage,
  fetchAppInfo,
  appInfo,
  setSubmitDelay,
}) => {
  const { t } = useTranslation("app");

  const handleOnLoad = () => {
    // reset userConfig
    setSubmitDelay({ submitDelay: 250 });

    // in some cases they may have leftover entries to sync
    fetchWaitingToSyncCount((count) => {
      if (count) {
        window.dispatchEvent(new Event("REPLAY_REQUESTS"));
      }
    });
  };

  const handleSyncSuccess = (event) => {
    setLastSync({ lastSyncDate: event.detail.date });
    showAppMessage({
      variant: "success",
      duration: 2000,
      message: t("sync.success"),
    });
  };

  const handleSyncError = () => {
    showAppMessage({
      variant: "warning",
      duration: 2000,
      message: t("sync.error"),
    });
  };

  const handleConnectionChange = (event) => {
    if (event.type === "online") {
      // check for new version
      fetchAppInfo();

      // clear the api requests queue
      window.dispatchEvent(new Event("REPLAY_REQUESTS"));
    }
  };

  // delete the main.*.js cache items in the workbox-runtime cache
  const deleteCaches = async () => {
    logger("app:debug")("Deleting cache entries.");
    const cacheNames = await window.caches.keys();
    const runtimeCache = cacheNames.find((cacheName) =>
      /^workbox-runtime/.test(cacheName)
    );
    const cache = await window.caches.open(runtimeCache);
    const requests = await cache.keys();
    const mainJsRequests = requests.filter((cacheRequest) =>
      /\/main\.[^.]+\.js$/.test(cacheRequest.url)
    );
    mainJsRequests.forEach((req) => {
      logger("app:debug")("Deleting cache entry: ", req.url);
      cache.delete(req);
    });
  };

  // throughout dev and testing, we usually get the latest app and service worker
  //  because we close the tab, and fire it back up whenever
  // or we reload the page and check the version - we want to automate this
  // so in the Kiosk mode, with app forefront all the time, we're not really going
  //  to get foreground events
  // 3-pronged approach: react-page-visibility, check once every 30 minutes, and online event
  // if the server version is greater than the running version, reload the page, delete old caches
  const updateAppIfNeeded = async () => {
    // best to disable completely in dev, otherwise how can you run eg. yarn start-staging?
    if (CONFIG.skipAppUpdateCheck) {
      logger("app:info")("App update check is skipped");
      return;
    }
    logger("app:debug")("latest app: ", appInfo.appInfo);
    const {
      appInfo: { version, buildNumber },
    } = appInfo;
    logger("app:debug")("current app: ", APP_INFO);
    if (isAppUpdateAvailable({ version, buildNumber })) {
      logger("app:info")("Reloading the app.");
      showAppMessage({
        variant: "success",
        message: t("updated", { version: `${version} build ${buildNumber}` }),
        duration: null,
      });
      await deleteCaches();
      setTimeout(() => document.location.reload(), 500);
    } else {
      logger("app:info")("App is up to date.");
    }
  };

  const handleUncaughtError = (event) => {
    const { message, filename, lineno, colno, error } = event;
    if (message.toLowerCase().indexOf("script error") > -1) {
      alert(`Script Error: See Browser Console for Detail. ${message}.`);
    } else {
      alert(message, filename, lineno, colno, error);
    }
    return false;
  };

  if (appInfo.status === "success") {
    updateAppIfNeeded();
  }

  React.useEffect(() => {
    window.addEventListener("sync-complete", handleSyncSuccess);
    window.addEventListener("sync-error", handleSyncError);
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("error", handleUncaughtError);
    window.addEventListener("load", handleOnLoad);

    // 30 mins
    const appCheckTimer = setInterval(() => fetchAppInfo(), 30 * 60 * 1000);

    return () => {
      window.removeEventListener("sync-complete", handleSyncSuccess);
      window.removeEventListener("sync-error", handleSyncError);
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("error", handleUncaughtError);
      window.removeEventListener("load", handleOnLoad);

      clearInterval(appCheckTimer);
    };
  });

  return (
    <PageVisibility
      onChange={(isVisible) => {
        if (isVisible) {
          fetchAppInfo();
        }
      }}
    >
      {children}
    </PageVisibility>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  setLastSync: PropTypes.func.isRequired,
  showAppMessage: PropTypes.func.isRequired,
  fetchAppInfo: PropTypes.func.isRequired,
  setSubmitDelay: PropTypes.func.isRequired,
  appInfo: PropTypes.shape({
    status: PropTypes.string,
    appInfo: PropTypes.shape({
      version: PropTypes.string,
      buildDate: PropTypes.string,
      buildNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default App;
