import { Workbox } from 'workbox-window';
import logger from 'debug';

const registerSW = () => {
  const wb = new Workbox('./service-worker.js');

  wb.addEventListener('waiting', () => {
    logger('app:info')('A new service worker has been installed, waiting for activation.');
  });

  wb.addEventListener('activated', () => {
    logger('app:info')('Service worker activated and ready.');
  });

  // receive postMessage from SW (ie data was sync'd)
  wb.addEventListener('message', (event) => {
    logger('app:info')('Message received: ', event.data);
    const { name, date } = event.data;
    const syncEvent = new CustomEvent(name, { detail: { date } });
    window.dispatchEvent(syncEvent);
  });

  // empty the replay queue, which holds our offline wasteEntry records
  window.addEventListener('REPLAY_REQUESTS', () => {
    wb.messageSW({ type: 'REPLAY_REQUESTS' });
  });

  // Register the service worker
  wb.register();
};

export default registerSW;
