import originalFetch from "isomorphic-fetch";
import { put, call, select, takeLatest } from "redux-saga/effects";
import fetchRetry from "fetch-retry";
import logger from "debug";
import { getI18n } from "react-i18next";
import moment from "moment";
import { toLower, find, get, omit, join, map } from "lodash";
import { saveWasteEntry, newWasteEntry, updateProfile } from "./actions";
import bamcoApiUrl from "~/api";
import { showAppMessage } from "~/AppMessage/actions";
import { setLastSync } from "~/App/actions";
import getWasteEntry from "./selectors";
import getAppSettings from "~/Settings/selectors";
import WeightCalculator from "~/Summary/WeightCalculator";
import DESTINATIONS from "~/Destination/destinations";
import { fetchProfiles } from "~/Settings/actions";
import { precacheContainerImages } from "~/Settings/precache";

// Workbox only retries requests that fail due to NetworkError
// This ensures waste entries are also retried when server is down (returns e.g. 503)
const fetch = fetchRetry(originalFetch, {
  retries: 1000, // this will try for about ~8h
  retryDelay: 30000, // 30s
  retryOn(attempt, error, response) {
    // retry when online and on 5xx status codes
    return response && response.status >= 500 && navigator.onLine;
  },
});

const transformWasteEntry = (wasteEntry, appSettings, notes) => {
  const isExact = wasteEntry.quantity.type === "exact";
  const typeOrReason =
    wasteEntry.kind === "RED_KITCHEN_WASTE"
      ? wasteEntry.whyTossed
      : wasteEntry.kind;
  const record = {
    // ISO with TZ eg. 2021-11-14T11:29:09-07:00
    created_date: moment().format(),

    campus_id: appSettings.campus.id.toString(),
    tablet_id: appSettings.tabletId.toString(),
    kitchen_id: appSettings.kitchen.id.toString(),
    profile_id: wasteEntry.profile.id.toString(),

    kind_of_waste: toLower(typeOrReason),
    waste_destination: find(DESTINATIONS, { slug: wasteEntry.destination })
      .submit,
    type_of_waste: wasteEntry.type, // eg dry

    exact_weight_reported: isExact ? 1 : 0, // exact weight _or_ volume

    // non-exact
    container_fill_level: isExact ? 0 : wasteEntry.quantity.percentage,
    container_id: isExact ? "" : wasteEntry.container.id,
    container_type: isExact ? "" : wasteEntry.container.name,
    num_containers: (isExact ? 0 : wasteEntry.quantity.count).toString(),

    lbs_waste: `${new WeightCalculator(wasteEntry).getWeight().toFixed(2)} lbs`,
    notes
  };
  return isExact
    ? omit(record, [
        "container_fill_level",
        "container_id",
        "container_type",
        "num_containers",
      ])
    : record;
};

const doFetch = async (wasteEntry) => {
  const response = await fetch(bamcoApiUrl("saveWasteEntry"), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(wasteEntry),
  });
  const json = await response.json();
  return {
    result: json,
    status: response.status,
  };
};

function* saveWasteEntryWorker(action) {
const notes = action.payload;
 yield put(saveWasteEntry.request());

  const appSettings = yield select(getAppSettings);
  const wasteEntry = yield select(getWasteEntry);
  const transformed = transformWasteEntry(wasteEntry, appSettings, notes);
  const t = (key) => getI18n().t(key, { ns: "saveWasteEntry", lng: localStorage.getItem("lngTemp") || "en" });

  try {
    const response = yield call(doFetch, transformed);
    const { result, status } = response;
    if (status === 200) {
      logger("app:info")("Recorded waste entry: ", transformed);
      yield put(showAppMessage({ variant: "success", message: t("success") }));
      yield put(saveWasteEntry.success({ result }));
      yield put(newWasteEntry());
      yield put(setLastSync({ lastSyncDate: new Date().getTime() }));      
    } else {
      // this is a problem with the payload, no point in saving for later sync
      const { errors } = result;
      const msg = join(map(errors, "msg"), " ");
      logger("app:error")("Failed to record waste entry: ", msg);
      logger("app:info")("Waste entry: ", transformed);
      yield put(saveWasteEntry.failure({ errors }));
      yield put(
        showAppMessage({
          variant: "error",
          message: `${t("fail")}: ${msg}`,
        })
      );
    }
  } catch (err) {
    // any error in saving, offline or server fault, and workbox caches for later
    yield put(newWasteEntry());
    yield put(
      showAppMessage({
        variant: "info",
        duration: 5000,
        message: t("success_offline"),
      })
    );
    logger("app:warn")("Queuing for later submission: ", transformed);
  }
}

function* updateProfileWorker(action) {
  const wasteEntry = yield select(getWasteEntry);
  const { profiles } = action.payload;
  const profile = find(profiles, { id: get(wasteEntry, "profile.id") });
  yield put(updateProfile({ profile }));
  precacheContainerImages(profile);
}

function* saveWasteEntryWatcher() {
  yield takeLatest(saveWasteEntry.TRIGGER, saveWasteEntryWorker);
}

function* fetchProfilesWatcher() {
  yield takeLatest(fetchProfiles.SUCCESS, updateProfileWorker);
}

export default [saveWasteEntryWatcher, fetchProfilesWatcher];
